<template>
    <div class="container flex flex-col">
        <div class="bg-white-pure list-wrapper">
            <div class="flex pb-8 items-center">
                <EpisodesSvg class="text-primary-light w-10 mr-2" />
                <h1 class="text-black mr-2 text-xl lg:text-2xl" data-cy="name">Episodes</h1>
            </div>
            <div class="flex items-center mb-6">
                <fa-icon icon="filter" class="tablet-hide text-sm text-black mr-4" />
                <LocationProgramFilter
                    class="flex mr-2"
                    dense
                    single-line
                    hide-details
                    outlined
                    clearable
                    v-model="filter.locationProgram"
                    @change="filterChangeHandler"
                />
                <div class="w-200 mr-2">
                    <v-select
                        dense
                        single-line
                        hide-details
                        outlined
                        clearable
                        v-model="filter.status"
                        :items="sortItems(episodeStatuses)"
                        label="Status"
                        @change="filterChangeHandler"
                    ></v-select>
                </div>
                <div class="w-200 mr-2">
                    <v-autocomplete
                        dense
                        single-line
                        hide-details
                        outlined
                        clearable
                        v-model="filter.worker"
                        label="Assigned Worker"
                        :items="sortItems(workersByLocationProgram)"
                        @change="filterChangeHandler"
                    ></v-autocomplete>
                </div>
                <v-btn
                    depressed
                    data-cy="resetFiltersHandler"
                    class="mr-4"
                    @click="resetFiltersHandler"
                    >Reset</v-btn
                >
            </div>
            <div class="a-episode-list">
                <div class="flex flex-row">
                    <div class="flex items-center w-full">
                        <EpisodeTable
                            ref="episodeTable"
                            :table-data="episodes"
                            :offset="pagination.offset"
                            :limit="pagination.limit"
                            :sort="sort"
                            :total="total"
                            :loading="loading"
                            @update-pagination="updatePagination"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import EpisodeTable from '@/components/episodes/partials/EpisodeTable'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import GET_EPISODES from '@/graphql/queries/getEpisodes.gql'
import GET_USERS from '@/graphql/queries/getUsers.gql'
import EpisodesSvg from '@/assets/icons/nav/icons_episodes.svg'
import LocationProgramFilter from '@/components/shared/LocationProgramFilter'
import sortItems from '@/components/shared/mixins/sortItems'
import { watch } from 'vue'

const DEFAULT_PAGINATION = {
    offset: 0,
    limit: 10
}
const DEFAULT_FILTER = {
    locationProgram: {
        locationId: null,
        locationProgramId: null
    },
    status: null,
    worker: null
}
const DEFAULT_SORT = ['startDate desc']

export default {
    name: 'EpisodeList',
    mixins: [BackendHelpers, HttpMixin, ClientHelpers, sortItems],
    components: { EpisodeTable, EpisodesSvg, LocationProgramFilter },
    data() {
        return {
            loading: false,
            episodes: [],
            users: [],
            total: 0,
            pagination: { ...DEFAULT_PAGINATION },
            sort: [...DEFAULT_SORT],
            filter: {
                ...DEFAULT_FILTER,
                locationProgram: { ...DEFAULT_FILTER.locationProgram }
            }
        }
    },
    apollo: {
        episodes: {
            query: GET_EPISODES,
            variables() {
                const { status, ...pagination } = this.pagination
                const statusCompare = this.filter.status ? { _eq: this.filter.status } : {}
                const locationProgramsCompare =
                    this.locationProgramIds && this.locationProgramIds.length
                        ? {
                              _in: this.locationProgramIds
                          }
                        : {}
                const workerCompare = this.filter.worker ? { _eq: this.filter.worker } : {}
                return {
                    ...pagination,
                    statusCompare,
                    orderBy: snakecaseKeys(this.orderBy, { deep: true }),
                    locationProgramsCompare,
                    workerCompare
                }
            },
            result({ data: { episodes, total } }) {
                this.total = total.aggregate.count
                this.episodes = camelcaseKeys(episodes || [], { deep: true })
            },
            watchLoading(isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, { deep: true })
            }
        }
    },
    beforeMount() {
        this.loadFilter(this.$route.query)
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', null)
    },
    methods: {
        loadFilter(query) {
            this.filter.locationProgram = {
                locationId:
                    (!isNaN(query.locationId) && parseInt(query.locationId)) ||
                    DEFAULT_FILTER.locationProgram.locationId,
                locationProgramId:
                    (!isNaN(query.locationProgramId) && parseInt(query.locationProgramId)) ||
                    DEFAULT_FILTER.locationProgram.locationProgramId
            }
            this.filter.status = query.status || DEFAULT_FILTER.status
            this.filter.worker = query.worker || DEFAULT_FILTER.worker

            this.pagination.limit =
                (!isNaN(query.limit) && parseInt(query.limit)) || DEFAULT_PAGINATION.limit
            this.pagination.offset =
                (!isNaN(query.offset) && parseInt(query.offset)) || DEFAULT_PAGINATION.offset

            this.sort = (query.sort && (Array.isArray(query.sort) ? query.sort : [query.sort])) || [
                ...DEFAULT_SORT
            ]
        },
        updateRoute() {
            const query = {
                ...this.filter,
                locationId: this.filter.locationProgram?.locationId,
                locationProgramId: this.filter.locationProgram?.locationProgramId,
                ...this.pagination,
                sort: this.sort.join(',')
            }

            delete query.locationProgram

            this.$router.replace({ query }).catch(() => {})
        },
        filterChangeHandler() {
            this.resetPage()
            this.updateRoute()
        },
        updatePagination({ page, itemsPerPage, sortBy, sortDesc }) {
            if (itemsPerPage * (page - 1) > this.total) return

            this.pagination.limit = itemsPerPage
            this.pagination.offset = this.pagination.limit * (page - 1)

            this.sort = sortBy.map((fieldName, i) => `${fieldName}${sortDesc[i] ? ' desc' : ''}`)

            this.updateRoute()
        },
        resetPage() {
            this.pagination.offset = 0
        },
        resetFiltersHandler() {
            this.filter.locationProgram = {
                locationId: null,
                locationProgramId: null
            }
            this.filter.status = null
            this.filter.worker = null

            this.filterChangeHandler()
        }
    },
    computed: {
        ...mapState({
            loggedInUser: (state) => state.app.loggedInUser,
            staticChoices: (state) => state.app.staticChoices
        }),
        locationProgramIds() {
            const { locationId, locationProgramId } = this.filter.locationProgram

            if (locationProgramId) return [locationProgramId]
            else if (!locationId) return this.loggedInUser.locationProgramIds

            return this.loggedInUser.locationPrograms
                .filter(({ location: { locationId: locId } }) => locId === locationId)
                .map(({ locationProgramId }) => locationProgramId)
        },
        episodeStatuses() {
            return this.staticChoices['episode.status']
        },
        workersByLocationProgram() {
            if (!this.filter.locationProgram.locationProgramId) return this.workersByLoggedInUser

            return this.workersByLoggedInUser.filter((worker) =>
                worker.locationProgramIds.includes(this.filter.locationProgram.locationProgramId)
            )
        },
        orderBy() {
            let orderBy = {}
            if (this.sort?.length) {
                this.sort.forEach((sortOne) => {
                    let [fieldName, sortDir] = sortOne.split(' ')
                    sortDir = sortDir === 'desc' ? 'desc_nulls_last' : 'asc_nulls_first'

                    switch (fieldName) {
                        case 'locationProgram':
                            sortDir = {
                                location: {
                                    name: sortDir
                                },
                                program: {
                                    name: sortDir
                                }
                            }
                            break
                        case 'client':
                            sortDir = {
                                first_name: sortDir,
                                last_name: sortDir
                            }
                            break
                        case 'worker':
                            sortDir = {
                                first_name: sortDir,
                                last_name: sortDir
                            }
                            break
                    }
                    orderBy[fieldName] = sortDir
                })
            }

            return orderBy
        }
    }
}
</script>
